const dataInsert = [
  {
    value: "{{NAMA PENERIMA}}",
    desc: "Nama Penerima email",
  },
  {
    value: "{{URL://}}",
    desc: "Link URL Pengajuan",
  },
  {
    value: "{{No pengajuan}}",
    desc: "Nomer Pengajuan",
  },
  {
    value: "{{tanggal sekarang}}",
    desc: "Tanggal hari ini",
  },
  {
    value: "{{BR}}",
    desc: "Line baru",
  },
];

export default dataInsert;

const opsiStatus = [
  { id: "Needs Approval", label: "Needs Approval" },
  { id: "Urgent", label: "Urgent" },
  { id: "Printed", label: "Printed" },
  { id: "Reject", label: "Reject" },
  { id: "Approved", label: "Approved" },
  { id: "Batal", label: "Batal" },
];

export default opsiStatus;

import moment from "moment";
import NumberFormat from "react-number-format";

const DummyDataSO = [
  {
    ShippingZone: "SIDOARJO",
    NoSalesOrder: "SO0001",
    NoShipment: "Ship1234",
    QtyDelivWhole: "5",
    QtyDelivLoose: "5",
    CustomerName: "SEKAR BUMI",
    Address: "JL Raya Besar 12V",
    SODate: "25/12/2022",
    DeliveryDate: "25/12/2022",
    Weight: "5kg",
    Volume: "5kg",
    CreatedbySO: "DJUWARIAH",
    BatchNbr: "BC12345",
    StatusBatch: "Open",
    POSource: "PO12345",
    NoPurchaseOrder: "PO12345",
  },
  {
    ShippingZone: "SIDOARJO",
    NoSalesOrder: "SO0002",
    NoShipment: "Ship1234",
    QtyDelivWhole: "5",
    QtyDelivLoose: "5",
    CustomerName: "SEKAR LANGIT",
    Address: "JL Raya Besar 12V",
    SODate: "25/12/2022",
    DeliveryDate: "25/12/2022",
    Weight: "5kg",
    Volume: "5kg",
    CreatedbySO: "DJUWARIAH",
    BatchNbr: "BC12345",
    StatusBatch: "Open",
    POSource: "PO12345",
    NoPurchaseOrder: "PO12345",
  },
  {
    ShippingZone: "SIDOARJO",
    NoSalesOrder: "SO0003",
    NoShipment: "Ship1234",
    QtyDelivWhole: "5",
    QtyDelivLoose: "5",
    CustomerName: "SEKAR BULAN",
    Address: "JL Raya Besar 12V",
    SODate: "25/12/2022",
    DeliveryDate: "25/12/2022",
    Weight: "5kg",
    Volume: "5kg",
    CreatedbySO: "DJUWARIAH",
    BatchNbr: "BC12345",
    StatusBatch: "Open",
    POSource: "PO12345",
    NoPurchaseOrder: "PO12345",
  },
  {
    ShippingZone: "SIDOARJO",
    NoSalesOrder: "SO0004",
    NoShipment: "Ship1234",
    QtyDelivWhole: "5",
    QtyDelivLoose: "5",
    CustomerName: "SEKAR BINTANG",
    Address: "JL Raya Besar 12V",
    SODate: "25/12/2022",
    DeliveryDate: "25/12/2022",
    Weight: "5kg",
    Volume: "5kg",
    CreatedbySO: "DJUWARIAH",
    BatchNbr: "BC12345",
    StatusBatch: "Open",
    POSource: "PO12345",
    NoPurchaseOrder: "PO12345",
  },
];

export default DummyDataSO;
